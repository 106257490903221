import React, { useContext, useEffect, useState } from 'react';
import Select from './components/Select/Select';
import WalletCard from './components/WalletCard';
import WalletReminderCard from './components/WalletReminderCard';
import WalletTransaction from './components/WalletTransaction';
import { useQuery } from '@apollo/client';
import { SCHOOL_WALLET } from './Querries';
import { dashboardContext } from '../../Dashboard';
function Wallet() {
	const [options, setOptions] = useState({
		name: 'WhatsApp',
	});
	const [loading, setLoading] = useState(false);

	const { school_data } = useContext(dashboardContext);
	const data = school_data;

	const school_wallet =
		options?.name === 'WhatsApp'
			? data?.school?.whatsapp_wallet
			: options?.name === 'SMS'
			? data?.school?.sms_wallet
			: '';

	const rateType =
		options?.name === 'WhatsApp'
			? school_data?.school?.whatsapp_rate
			: options?.name === 'SMS'
			? school_data?.school?.sms_rate
			: '';

	const next_installment = school_data?.school?.instllment_wise_fee_report.find(
		(installment: any) => new Date() < new Date(installment.due_date),
	);
	const reminderPolicy = data?.school?.fee_reminder_policy?.length;

	const nextMonthRequireBalance =
		reminderPolicy * (next_installment?.total_students - next_installment?.total_students_paid) * rateType;
	useEffect(() => {
		setLoading(true);

		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [options]);
	return (
		<div className='pr-4'>
			<div className='flex justify-between items-center pr-5 my-2'>
				<h3 className=' text-edvion_black font-semibold text-4xl ml-10'>Wallet</h3>
				<Select
					selected={options}
					setSelected={setOptions}
					options={['WhatsApp', 'SMS'].map((type: any) => {
						return { name: type };
					})}
					className='w-48'
					boxStyle='border-b-2 border-[#CBCBCB] '
					icon={<i className='fa-solid text-[#6F6AF8] fa-chevron-down'></i>}
				/>
			</div>
			<div className='grid grid-cols-3 gap-x-8 mt-8 ml-10'>
				{loading ? (
					<>
						<div className='rounded-lg bg-gray-300 animate-pulse shadow h-44 w-full'></div>
						<div className='rounded-lg bg-gray-300 animate-pulse shadow h-44 w-full'></div>
					</>
				) : (
					<>
						<WalletCard
							label='Current Balance'
							amount={school_wallet?.balance}
							amountStyle='text-[40px] text-[#EDAE0D]'
							type={options}
							balance={nextMonthRequireBalance}
							rate={rateType}
							icon={<i className='fa-solid text-4xl text-[#6F6AF8] fa-wallet'></i>}
							creditBoxStyle='bg-[#EDAE0D80] text-opacity-50 text-lg font-medium'
						/>
						<WalletCard
							label='Required Balance (next month)'
							amount={98884}
							type={options}
							balance={nextMonthRequireBalance || 0}
							rate={rateType}
							amountStyle='text-[40px] text-[#2EB418E5]'
							icon={<i className='fa-solid text-[#2EB418E5] text-4xl fa-wallet'></i>}
							creditBoxStyle='bg-[#93D789] text-opacity-50 text-lg font-medium'
						/>
					</>
				)}
			</div>
			<div className='ml-10 mt-4'>
				{loading ? (
					<div className='rounded-lg bg-gray-300 animate-pulse shadow h-5 max-w-xl'></div>
				) : (
					<p className='text-[#AAAAAA] text-base font-medium'>
						For Wallet Recharge please click the respective communication mode option *
					</p>
				)}
			</div>
			<WalletTransaction rate={rateType} data={school_wallet} loading={loading} />
		</div>
	);
}

export default Wallet;
